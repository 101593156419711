<template>
    <div ref="editorWrapper">
        <ckeditor :editor="editor" v-model="body" :config="config" @ready="editorReady"></ckeditor>
    </div>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import _ from 'underscore';

import CKEditor from '@ckeditor/ckeditor5-vue';
import Editor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import List from '@ckeditor/ckeditor5-list/src/list';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Clipboard from '@ckeditor/ckeditor5-clipboard/src/clipboard';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Colors from '@/components/shared/colors';

@Component({
    components: {
        ckeditor: CKEditor.component
    }
})
export default class RichTextEditor extends Vue {

    @Prop({type: String, default: null}) value;

    get body() {
        return this.value;
    }

    set body(value) {
        this.$emit('input', value);
    }

    editor = Editor;

    config = {
        plugins: [
            Essentials, Paragraph,
            Bold, Italic, Strikethrough, Underline, Code, Subscript, Superscript,
            Link, List, Font, Alignment, Indent, IndentBlock, BlockQuote,
            Heading, Clipboard, RemoveFormat
        ],

        fontSize: {
            options: _.range(12, 50).filter((i) => {
                return i < 18 ||
                    (i < 30 && 0 === i % 2) ||
                    (i < 50 && 0 === i % 4) ||
                    (i < 80 && 0 === i % 6) ||
                    0 === i % 8;
            })
        },

        fontColor: {
            colors: Colors
        },

        fontBackgroundColor: {
            colors: Colors
        },

        indentBlock: {
            offset: 1,
            unit: 'em'
        },

        toolbar: {
            items: [
                'heading', '|',
                'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', 'alignment', '|',
                'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', '|',
                'bulletedList', 'numberedList', 'indent', 'outdent', 'blockQuote', '|',
                'link', '|',
                'removeformat', 'undo', 'redo'
            ]
        }
    };

    editorReady(editor) {
        this.$refs.editorWrapper.prepend(editor.ui.view.toolbar.element);
    }
}
</script>
<style scoped>

</style>
<style>
    div.ck-content {
        height: 250px;
    }

    ul.ck-list {
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>