<template>
    <b-card bg-variant="light" title="Comments">
        <b-card-body>
            <b-row>
                <b-col>
                    <rich-text-editor v-model="editComment.comment" v-if="editMode"/>
                    <div v-html="comment.comment" v-else></div>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col cols="8">
                    <template v-if="0 < orgComments.length">
                        {{ comment.updatedBy }} on {{ comment.lastUpdated | lastUpdate }}
                    </template>
                </b-col>
                <b-col cols="4" class="clearfix">
                    <div class="float-right" v-if="editMode">
                        <b-link @click="saveComment">save</b-link> | <b-link @click="stopEditing">cancel</b-link>
                    </div>
                    <div class="float-right" v-else>
                        <b-link @click="startEditing">edit</b-link> | <b-link @click="$bvModal.show('org-comment-modal-'+organization.id)">show history</b-link>
                    </div>
                </b-col>
            </b-row>
        </b-card-body>
        <b-modal :id="'org-comment-modal-'+organization.id" size="lg" scrollable centered :title="'Comments about ' + organization.name">
            <b-table size="sm" head-variant="dark" bordered sticky-header striped  :fields="historyFields" :items="comments">
                <template v-slot:cell(comment)="row">
                    <span v-html="row.item.comment"></span>
                </template>
                <template v-slot:cell(lastUpdated)="row">{{ row.item.lastUpdated | lastUpdate }}</template>
            </b-table>
            <template v-slot:modal-footer="{ ok }">
                <b-button size="sm" variant="primary" @click="ok">Close</b-button>
            </template>
        </b-modal>
    </b-card>
</template>
<script>

import {Component, Prop, Vue} from 'vue-property-decorator';
import _ from 'underscore';
import {Organization} from '@/model/organization';
import RichTextEditor from '@/components/shared/RichTextEditor.vue';
import formatters from '@/util/formatters';
import {NULL_COMMENT} from '@/model/comment';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        RichTextEditor
    },

    filters: {
        lastUpdate: (lastUpdate) => formatters.date(lastUpdate, 'M/d/yyyy h:mm:ss aaaa')
    }
})
export default class ContractorComments extends Vue {

    @Prop({type: Organization}) organization;

    editComment = NULL_COMMENT.clone();

    editMode = false;

    orgComments = [];

    get comments() {
        return _.sortBy(this.orgComments, (c) => c.lastUpdated).reverse();
    }

    get historyFields() {
        return [{
            key: 'comment',
            label: 'Comment'
        }, {
            key: 'updatedBy',
            label: 'Username'
        }, {
            key: 'lastUpdated',
            label: 'Updated'
        }];
    }

    get comment() {

        if (_.isEmpty(this.comments)) {
            return NULL_COMMENT.clone();
        }

        return _.first(this.comments);
    }

    startEditing() {
        this.editMode = true;
        this.editComment.comment = this.comment.comment;
    }

    stopEditing() {
        this.editMode = false;
    }

    async saveComment() {
        try {
            this.editComment.recordId = this.organization.id;
            const savedComment = await this.$store.dispatch('organizations/saveComments', this.editComment);
            this.orgComments.push(savedComment);
            this.stopEditing();
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async mounted() {
        this.editMode = false;
        try {
            this.orgComments = await this.$store.dispatch('organizations/getComments', this.organization.id);
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }
}
</script>
<style scoped>

</style>