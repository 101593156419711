<template>
    <div>
        <b-table-lite borderless small stacked :fields="fields" :items="tableData">
            <template v-slot:cell(name)="row">
                <b-form-group :state="errorState('name')" invalid-feedback="Name is required.">
                    <b-input size="sm" v-model="row.item.workspace.name" :state="errorState('name')"/>
                </b-form-group>
            </template>
            <template v-slot:cell(type)="row">
                <b-form-group :state="errorState('type')" invalid-feedback="Type is required.">
                    <b-select size="sm" :options="contractorTypeOptions" v-model="row.item.workspace.type" :state="errorState('type')"/>
                </b-form-group>
            </template>
            <template v-slot:cell(address)="row">
                <b-row class="mb-2">
                    <b-col>
                        <b-form-group :state="errorState('address1')" invalid-feedback="Address is required.">
                            <b-input size="sm" v-model="row.item.workspace.address1" placeholder="Address" :state="errorState('address1')"/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col>
                        <b-input size="sm" v-model="row.item.workspace.address2" placeholder="Address (additional)"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <b-form-group :state="errorState('city')" invalid-feedback="City is required.">
                            <b-input size="sm" v-model="row.item.workspace.city" placeholder="City" :state="errorState('city')"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="4">
                        <b-form-group :state="errorState('state')" invalid-feedback="State/Province is required.">
                            <state-select v-model="row.item.workspace.state" size="sm" :state="errorState('state')" show-status/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="4">
                        <b-form-group :state="errorState('zip')" invalid-feedback="ZIP/Postal Code is required.">
                            <b-input size="sm" v-model="row.item.workspace.zip" placeholder="ZIP/Postal" :state="errorState('zip')"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </template>
            <template v-slot:cell(phone)="row">
                <b-row>
                    <b-col cols="6">
                        <b-form-group :state="errorState('phone')" invalid-feedback="Phone Number is required.">
                            <phone-number v-model="row.item.workspace.phone.formatted"
                                          input-class="form-control form-control-sm"
                                          @state="setPhoneState"
                                          show-status/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </template>
            <template v-slot:cell(status)="row">
                <b-row>
                    <b-col>
                        <b-form-checkbox v-model="row.item.workspace.participating" switch>
                            <b-badge :variant="row.item.workspace.participating ? 'success' : 'warning'">
                                {{ row.item.workspace.participating ? '' : 'Not'}} Currently Participating
                            </b-badge>
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row class="mt-3" v-if="showButtons">
                    <b-col>
                        <b-button-group size="sm">
                            <b-button variant="success" @click="$emit('save')">Save</b-button>
                            <b-button variant="primary" @click="$emit('cancel')">Cancel</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
            </template>
        </b-table-lite>
<!--        <b-row>-->
<!--            <b-col cols="12">-->
<!--                <b-card bg-variant="light" title="Contacts">-->
<!--                    <b-card-body>-->
<!--                        <ContractorContacts-->
<!--                                @update="refresh"-->
<!--                                :contractor="organization" />-->
<!--                    </b-card-body>-->
<!--                </b-card>-->
<!--            </b-col>-->
<!--        </b-row>-->
    </div>
</template>
<script>

import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {Organization, NULL_ORGANIZATION} from '@/model/organization';
import _ from 'underscore';
import StateSelect from '@/components/shared/StateSelect.vue';
import PhoneNumber from '@/components/shared/PhoneNumber.vue';
import ContractorContacts from './ContractorContacts.vue';

@Component({
    components: {
        StateSelect,
        PhoneNumber,
        ContractorContacts
    }
})
export default class ContractorEdit extends Vue {

    @Prop({type: Organization, default: NULL_ORGANIZATION}) value;
    @Prop({type: Boolean, default: true}) showButtons;

    phoneState = true;

    get organization() {
        return this.value;
    }

    get contractorTypeOptions() {
        const types = this.$store.getters['organizations/getContractorTypes'];

        return _.map(types, (t) => {
            return {
                value: t,
                text: t.type
            };
        });
    }

    get fields() {
        return [{
            key: 'name',
            label: 'Name:'
        }, {
            key: 'type',
            label: 'Type:'
        }, {
            key: 'address',
            label: 'Address:'
        }, {
            key: 'phone',
            label: 'Phone:'
        }, {
            key: 'status',
            label: 'Status:'
        }];
    }

    get tableData() {
        return [this.organization];
    }

    @Watch('organization.workspace.state')
    setState(state) {
        if (!!state) {
            this.organization.workspace.country = state.countryAbbreviation;
        }
    }

    errorState(field) {
        if ('phone' === field) {
            return this.phoneState;
        }
        return !this.organization.workspace.errors[field];
    }


    setPhoneState(state) {
        this.phoneState = state;
    }

    mounted() {
        this.setPhoneState(this.organization.workspace.phone.isValid);
    }
}
</script>

<style scoped>

</style>