<template>
    <div>
        <b-card bg-variant="light" title="Contacts">
            <b-card-body>
                <b-button-group
                        class="float-right">
                    <b-button
                            variant="primary"
                            v-if="!editContacts && !newContractor"
                            @click="startEditing"
                            size="sm">Edit Contacts</b-button>
                    <b-button
                            variant="danger"
                            v-if="!!editContacts && !newContractor"
                            @click="cancel"
                            size="sm">Cancel</b-button>
                    <b-button
                            variant="warning"
                            v-if="!!editContacts"
                            @click="addNewContact"
                            size="sm">Add New Contact</b-button>
                    <b-button
                            variant="success"
                            :disabled="contactsErrors"
                            v-if="!!editContacts && !newContractor"
                            @click="save"
                            size="sm">Save Changes</b-button>
                </b-button-group>
                <br /><br />
                <b-table-lite
                        v-if="!!contacts"
                        small
                        striped
                        hover
                        bordered
                        stacked="sm"
                        head-variant="dark"
                        :fields="fields"
                        :items="contacts">
                    <!-- First Name -->
                    <template v-slot:cell(firstName)="row">
                        <template
                                v-if="editContacts && row.item.editMode">
                            <b-form-group
                                    :state="errorState(row.item, 'firstName')"
                                    invalid-feedback="First name is required">
                                <b-input
                                        placeholder="First Name"
                                        v-model="row.item.firstName"
                                        :state="errorState(row.item, 'firstName')"
                                        size="sm" />
                            </b-form-group>
                        </template>
                        <template v-else>{{ row.item.firstName }}</template>
                    </template>
                    <!-- Last Name -->
                    <template v-slot:cell(lastName)="row">
                        <template
                                v-if="editContacts && row.item.editMode">
                            <b-form-group
                                    :state="errorState(row.item, 'lastName')"
                                    invalid-feedback="Last name is required">
                                <b-input
                                        placeholder="Last Name"
                                        v-model="row.item.lastName"
                                        :state="errorState(row.item, 'lastName')"
                                        size="sm" />
                            </b-form-group>
                        </template>
                        <template v-else>{{ row.item.lastName }}</template>
                    </template>
                    <!-- Title -->
                    <template v-slot:cell(title)="row">
                        <template
                                v-if="editContacts && row.item.editMode">
                            <b-form-group
                                    :state="errorState(row.item, 'title')"
                                    invalid-feedback="Title is required">
                                <b-input
                                        placeholder="Title"
                                        v-model="row.item.title"
                                        :state="errorState(row.item, 'title')"
                                        size="sm" />
                            </b-form-group>
                        </template>
                        <template v-else>{{ row.item.title }}</template>
                    </template>
                    <!-- Phone -->
                    <template v-slot:cell(phone)="row">
                        <template
                                v-if="editContacts && row.item.editMode">
                            <b-form-group
                                    :state="errorState(row.item, 'phone')"
                                    invalid-feedback="Valid phone number is required">
                                <!-- TODO v-model not working -->
                                <PhoneNumber
                                        show-status
                                        input-class="form-control form-control-sm"
                                        v-model="row.item.phone.formatted"
                                        @state="errorState(row.item, 'phone')" />
                            </b-form-group>
                        </template>
                        <template v-else>{{ row.item.phone | phone }}</template>
                    </template>
                    <!-- Email -->
                    <template v-slot:cell(email)="row">
                        <template
                                v-if="editContacts && row.item.editMode">
                            <b-form-group
                                    :state="errorState(row.item, 'email')"
                                    invalid-feedback="Valid email address is required">
                                <b-input
                                        placeholder="Email"
                                        v-model="row.item.email"
                                        :state="errorState(row.item, 'email')"
                                        size="sm" />
                            </b-form-group>
                        </template>
                        <template v-else>{{ row.item.email }}</template>
                    </template>
                    <!-- Actions -->
                    <template v-slot:cell(actions)="row" v-if="editContacts">
                        <b-button-group>
                            <b-button
                                    @click="remove(row.item)"
                                    variant="danger"
                                    size="sm">Delete</b-button>
                            <b-button
                                    v-if="!row.item.editMode"
                                    @click="edit(row.item)"
                                    variant="primary"
                                    size="sm">Edit</b-button>
                            <b-button
                                    v-if="row.item.editMode"
                                    @click="done(row.item)"
                                    variant="warning"
                                    :disabled="contactErrors(row.item)"
                                    size="sm">Done</b-button>
                        </b-button-group>
                    </template>
                </b-table-lite>
            </b-card-body>
        </b-card>
        <!-- NEW CONTACT MODAL -->
        <b-modal id="add-contact-modal"
                 ref="add-contact-modal"
                 size="lg"
                 title="Add New Contractor Contact"
                 header-bg-variant="dark"
                 header-text-variant="white"
                 button-size="sm"
                 hide-footer
                 scrollable>
            <b-form>
                <b-form-group
                        label="First Name"
                        :state="errorState(newContact, 'firstName')"
                        invalid-feedback="First name is required">
                    <b-input
                            placeholder="First Name"
                            v-model="newContact.firstName"
                            :state="errorState(newContact, 'firstName')"
                            size="sm" />
                </b-form-group>
                <b-form-group
                        label="Last Name"
                        :state="errorState(newContact, 'lastName')"
                        invalid-feedback="Last name is required">
                    <b-input
                            placeholder="Last Name"
                            v-model="newContact.lastName"
                            :state="errorState(newContact, 'lastName')"
                            size="sm" />
                </b-form-group>
                <b-form-group
                        label="Title"
                        :state="errorState(newContact, 'title')"
                        invalid-feedback="Title is required">
                    <b-input
                            placeholder="Title"
                            v-model="newContact.title"
                            :state="errorState(newContact, 'title')"
                            size="sm" />
                </b-form-group>
                <b-form-group
                        label="Phone"
                        :state="errorState(newContact, 'phone')"
                        invalid-feedback="Valid phone number is required">
                    <PhoneNumber
                            show-status
                            input-class="form-control form-control-sm"
                            v-model="newContact.phone.formatted"
                            @state="errorState(newContact, 'phone')" />
                </b-form-group>
                <b-form-group
                        label="Email"
                        :state="errorState(newContact, 'email')"
                        invalid-feedback="Valid email address is required">
                    <b-input
                            placeholder="Email"
                            v-model="newContact.email"
                            :state="errorState(newContact, 'email')"
                            size="sm" />
                </b-form-group>
                <b-button-group
                        class="float-right">
                    <b-button
                            @click="cancelNewContact"
                            variant="warning"
                            size="sm">Cancel</b-button>
                    <b-button
                            @click="saveNewContact"
                            variant="primary"
                            :disabled="contactErrors(newContact)"
                            size="sm">Save</b-button>
                </b-button-group>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator';
    import {sprintf} from 'sprintf-js';
    import _ from 'underscore';
    import {trimToEmpty, trimToNull} from '@/util/formatters';
    import {NULL_ORGANIZATION, NULL_CONTACT, Organization} from "@/model/organization";
    import formatters from "@/util/formatters";
    import PhoneNumber from '@/components/shared/PhoneNumber.vue';
    import {Phone} from "@/model/phone";
    import organizationDao from "../../../dao/organization_dao";
    import {errorModalOptions} from "../../../util/formatters";

    @Component({
        components: {
            PhoneNumber
        },
        filters: {
            phone: (phone) => {
                if (_.isObject(phone)) {
                    return phone.formatted;
                }
                else {
                    return phone;
                }
            }
        },
    })

    export default class ContractorContacts extends Vue {
        @Prop({type: Organization, default: function() { return NULL_ORGANIZATION; }}) contractor;
        editContacts = false;
        newContact = NULL_CONTACT.clone();

        get newContractor() {
            return this.contractor.isNew;
        }

        startEditing() {
            this.editContacts = true;
        }

        cancel() {
            this.resetContacts();
            this.editContacts = false;
        }

        resetContacts() {
            this.contractor.workspace.contacts = this.contractor.contacts;
            // console.log(this.contractor.contacts);
        }

        async save() {
            try {
                //Store call passes workspace and commits changes
                await this.$store.dispatch('organizations/saveOrganization', this.contractor, {root: true});
                this.editContacts = false;
                this.$emit('update');
            }
            catch (error) {
                this.resetContacts();
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }

        get contacts() {
            return this.contractor.workspace.contacts;
        }

        set contacts(contacts) {
            this.contractor.workspace.contacts = contacts;
        }

        resetNewContact() {
            this.newContact = NULL_CONTACT.clone();
        }

        addNewContact() {
            this.$bvModal.show('add-contact-modal');
        }

        cancelNewContact() {
            this.$bvModal.hide('add-contact-modal');
            this.resetNewContact();
        }

        saveNewContact() {
            this.contacts.push(this.newContact);
            this.$bvModal.hide('add-contact-modal');
            //Emit event for non-save actions (e.g. new contractor)
            if (this.contractor.isNew) {
                this.$emit('updated-contacts', this.contacts);
            }
            this.resetNewContact();
        }

        mounted() {
            // console.log(this.contractor);
            if (this.newContractor) {
                this.editContacts = true;
            }
        }

        get fields() {
            const fields = [{
                key: 'firstName',
                label: 'First Name'
            }, {
                key: 'lastName',
                label: 'Last Name'
            }, {
                key: 'title',
                label: 'Title'
            }, {
                key: 'phone',
                label: 'Phone'
            }, {
                key: 'email',
                label: 'Email'
            }];
            if (this.editContacts) {
                fields.push({
                    key: 'actions',
                    label: 'Actions'
                });
            }
            return fields;
        }

        errorState(contact, field) {
            return !contact.errors[field];
        }

        contactErrors(contact) {
            const errors = _.any(contact.fields, field => {
                const fieldError = contact.errors[field];
                // console.log(sprintf('Field: %s Error: %s', field, fieldError));
                return fieldError;
            });
            return errors;
        }

        get contactsErrors() {
            if (!_.isEmpty(this.contacts)) {
                const errors = _.any(this.contacts, contact => {
                    const contactError = this.contactErrors(contact);
                    return contactError || contact.editMode;
                });
                return errors;
            }
            else {
                return false;
            }
        }

        async remove(contact) {
            const check = await this.$bvModal.msgBoxConfirm(
                'Are you sure you wish to delete this contact? This action cannot be undone.', {
                    title: 'Confirm Delete Contact',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                });
            if (!check) {
                return;
            }
            try {
                await organizationDao.deleteContact(this.contractor.id, contact.id);
                //Rebuild contacts here in case edits were made
                const index = this.contacts.indexOf(contact);
                if (index >= 0) {
                    this.contacts.splice(index, 1);
                }
                //Emit event for non-save actions (e.g. new contractor)
                this.$emit('updated-contacts', this.contacts);
            }
            catch (error) {
                this.resetContacts();
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }

        edit(contact) {
            contact.editMode = true;
        }

        done(contact) {
            contact.editMode = false;
            //Emit event for non-save actions (e.g. new contractor)
            if (this.contractor.isNew) {
                this.$emit('updated-contacts', this.contacts);
            }
        }
    }
</script>

<style scoped>
    b-form-group.label {
        font-weight: bold;
    }
</style>