<template>
    <div>
        <b-row>
            <b-col md="6">
                <b-card bg-variant="light" title="Details">
                    <b-card-body>
                        <b-table-lite :hover="false" borderless small stacked :fields="fields" :items="tableData">
                            <template v-slot:cell(name)="row">{{row.item.name}} (<b-link @click="$emit('edit')">edit</b-link>)</template>
                            <template v-slot:cell(type)="row">{{row.item.type.type}}</template>
                            <template v-slot:cell(address)="row"><span v-html="formattedAddress"></span></template>
                            <template v-slot:cell(phone)="row">{{!!row.item.phone && row.item.phone.isValid ? row.item.phone.formatted : ''}}</template>
                        </b-table-lite>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col md="6">
                <contractor-comments :organization="organization"/>
            </b-col>
        </b-row>
<!--        <b-row>-->
<!--            <b-col cols="12">-->
<!--                <b-card bg-variant="light" title="Contacts">-->
<!--                    <b-card-body>-->
<!--                        <ContractorContacts-->
<!--                                @update="refresh"-->
<!--                                :contractor="organization" />-->
<!--                    </b-card-body>-->
<!--                </b-card>-->
<!--            </b-col>-->
<!--        </b-row>-->
    </div>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';
import {Organization, NULL_ORGANIZATION} from '@/model/organization';
import {sprintf} from 'sprintf-js';
import _ from 'underscore';
import {trimToEmpty, trimToNull} from '@/util/formatters';
import ContractorComments from './ContractorComments.vue';
import ContractorContacts from './ContractorContacts.vue';

@Component({
    components: {
        ContractorComments,
        ContractorContacts
    }
})
export default class ContractorDetails extends Vue {

    @Prop({type: Organization, default: null}) value;

    get organization() {
        return !this.value ? NULL_ORGANIZATION.clone() : this.value;
    }

    mounted() {
        // console.log(this.organization);
    }

    get fields() {
        return [{
            key: 'name',
            label: 'Name:'
        }, {
            key: 'type',
            label: 'Type:'
        }, {
            key: 'address',
            label: 'Address:'
        }, {
            key: 'phone',
            label: 'Phone:'
        }];
    }

    get tableData() {
        return [this.organization];
    }

    get formattedAddress() {
        return !this.organization.address1 ? '' : sprintf('%s%s<br/>%s, %s %s %s',
            this.organization.address1,
            !!this.organization.address2 ? '<br/>' + this.organization.address2 : '',
            this.organization.city,
            _.isObject(this.organization.state) ? this.organization.state.stateAbbreviation : this.organization.state,
            this.organization.zip,
            _.isEqual(this.organization.country.toUpperCase(), 'UNITED STATES OF AMERICA') ? 'US' : this.organization.country
        );
    }

    get formattedContact() {

        const org = this.organization;

        const fname = trimToEmpty(org.contactFirstName);
        const lname = trimToEmpty(org.contactLastName);
        const title = trimToNull(org.contactTitle);
        const phone = !!org.contactPhone && org.contactPhone.isValid ? org.contactPhone.formatted : null;
        const email = trimToNull(org.contactEmail);
        return sprintf('%s %s%s%s%s',
            fname,
            lname,
            !title ? '' : ', ' + title,
            !phone ? '' : '<br/>' + phone,
            !email ? '' : '<br/>' + email
        );


    }
}
</script>
<style scoped>

</style>