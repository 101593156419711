
import _ from 'underscore';

export const codes = [
    '#000000',
    '#c0c0c0',
    '#808080',
    '#ffffff',
    '#800000',
    '#ff0000',
    '#dc143c',
    '#800080',
    '#ff00ff',
    '#006400',
    '#008000',
    '#00ff00',
    '#808000',
    '#ffff00',
    '#f0e68c',
    '#000080',
    '#0000ff',
    '#008080',
    '#00ffff',
    '#ffa500'
];

export const names = [
    'Black',
    'Silver',
    'Gray',
    'White',
    'Maroon',
    'Red',
    'Crimson',
    'Purple',
    'Fuchsia',
    'Dark Green',
    'Green',
    'Lime',
    'Olive',
    'Yellow',
    'Khaki',
    'Navy',
    'Blue',
    'Teal',
    'Cyan',
    'Orange'
];

const Colors = _.range(0, names.length).map((i) => {
    return {
        color: codes[i],
        label: names[i]
    };
});

export default Colors;