<template>
    <div>
        <b-row>
            <b-col>
                <b-card class="mb-3">
                    <b-card-body>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-contractor-name" label="Contractor Name:">
                                    <b-input-group>
                                        <b-input id="search-contractor-name" size="sm" placeholder="Contractor Name" v-model="contractorNameFilter"/>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="contractorNameFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-contact-name" label="Contact Name:">
                                    <b-input-group>
                                        <b-input id="search-contact-name" size="sm" placeholder="Contact Name" v-model="contactNameFilter"/>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="contactNameFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-type" label="Type:">
                                    <b-input-group>
                                        <b-select id="search-type" size="sm" :options="contractorTypeOptions" v-model="typeFilter">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="typeFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-city" label="Contractor City:">
                                    <b-input-group>
                                        <b-input id="search-city" size="sm" placeholder="Contractor City" v-model="cityFilter"/>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="cityFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-state" label="Contractor State/Province:">
                                    <b-input-group>
                                        <state-select :id="'search-state'" size="sm" v-model="stateFilter"/>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="stateFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-status" label="Status:">
                                    <b-input-group>
                                        <b-select id="search-status" size="sm" v-model="statusFilter">
                                            <option :value="null"> - </option>
                                            <option :value="true">Participating</option>
                                            <option :value="false">Not Participating</option>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="statusFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-button-group size="sm">
                                    <b-button variant="info" @click="clearFilters">Clear</b-button>
                                    <b-button variant="primary" @click="exportContractors">Export Contractors</b-button>
                                </b-button-group>
                            </b-col>
                            <b-col cols="6" class="clearfix">
                                <b-button variant="dark" size="sm" class="float-right" v-b-modal.add-contractor-modal>New Contractor</b-button>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4">
                <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage"/>
            </b-col>
            <b-col cols="8">
                <b-form-group :label="'Records Per Page: ' + perPage">
                    <b-input type="range" v-model="perPage" min="10" max="100"></b-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-table small striped hover bordered stacked="md" head-variant="dark"
                             :fields="resultFields"
                             :items="results"
                             :per-page="perPage"
                             :current-page="currentPage"
                             @row-clicked="rowClick">
                        <template v-slot:cell(type)="row">
                            {{row.item.type.type}}
                        </template>
                        <template v-slot:cell(state)="row">{{row.item.state | formatState }}</template>
                        <template v-slot:cell(status)="row">
                            <b-badge :variant="row.item.participating ? 'success' : 'warning'">
                                {{ row.item.participating ? '' : 'Not '}} Currently Participating
                            </b-badge>
                        </template>
                        <template v-slot:cell(buttons)="row">
                            <b-link variant="link" @click="row.toggleDetails">{{ row.detailsShowing ? 'Hide' : 'Show'}} Details</b-link>
                        </template>
                        <template v-slot:row-details="row">
                            <contractor-edit v-if="row.item.editMode"
                                             v-model="row.item"
                                             @cancel="row.item.rollback()"
                                             @save="saveOrganization(row.item)"/>
                            <contractor-details v-else
                                                v-model="row.item"
                                                @edit="row.item.editMode=true"/>
                            <ContractorContacts :contractor="row.item"
                                                @update="updateContacts(row)"/>
                        </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage"/>
            </b-col>
        </b-row>
        <b-modal id="add-contractor-modal"
                 ref="add-contractor-modal"
                 size="xl"
                 title="Add New Contractor"
                 header-bg-variant="dark"
                 header-text-variant="white"
                 button-size="sm"
                 cancel-variant="primary"
                 ok-variant="success"
                 scrollable
                 @ok="saveNewContractor">
            <b-card bg-variant="light">
                <contractor-edit v-model="newContractor" :show-buttons="false"/>
            </b-card>
            <ContractorContacts :contractor="newContractor" @updated-contacts="updateNewContractorContacts" />
            <template v-slot:modal-cancel>Cancel</template>
            <template v-slot:modal-ok>Save New Contractor</template>
        </b-modal>
    </div>
</template>
<script>

import {Vue, Component} from 'vue-property-decorator';
import store from '@/store/store';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import ContractorDetails from './ContractorDetails.vue';
import ContractorEdit from './ContractorEdit.vue';
import ContractorContacts from './ContractorContacts.vue';
import StateSelect from '@/components/shared/StateSelect.vue';
import _ from 'underscore';
import {trimToNull} from '@/util/formatters';
import {NULL_ORGANIZATION} from '@/model/organization';
import download from '@/util/downloader';
import {OrganizationContact as NULL_CONTACT, OrganizationContact} from "../../../model/organization";
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        ContractorDetails,
        ContractorEdit,
        StateSelect,
        ContractorContacts
    },

    filters: {
        formatState: (state) => {

            switch (true) {
                case _.isNull(state): return '';
                case _.isString(state): return state; // TODO: possibly do some lookup, to return name or abbreviation
                case _.isObject(state): return state.stateAbbreviation; // TODO: likewise
                default: return state; // TODO: Do some determination as to what the heck this is! :)
            }
        }
    }
})
export default class Contractors extends Vue {

    contractorNameFilter = null;
    contactNameFilter = null;
    typeFilter = null;
    cityFilter = null;
    stateFilter = null;
    statusFilter = null;

    perPage = 10;
    currPage = 1;

    newContractor = NULL_ORGANIZATION.clone();

    updateNewContractorContacts(contacts) {
        //Solve for double two-way binding fail by mounting with default and listening for event
        this.newContractor.workspace.contacts = contacts;
    }

    async updateContacts() {
        //Reload to get contact IDs
        try {
            await store.dispatch('organizations/loadOrganizations');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    get currentPage() {
        if (this.currPage > this.pages) {
            return this.pages;
        }
        else {
            return this.currPage;
        }
    }

    set currentPage(num) {
        const page = parseInt(num, 10);
        switch (true) {
            case _.isNaN(page):
            case 0 >= page:
                this.currPage = 1;
                break;
            case page > this.pages:
                this.currPage = this.pages;
                break;
            default:
                this.currPage = page;
        }
    }

    get pages() {
        return Math.ceil(this.results.length / this.perPage) || 1;
    }

    get totalRows() {
        return this.results.length;
    }

    get contractorTypeOptions() {
        const types = this.$store.getters['organizations/getContractorTypes'];

        return _.map(types, (t) => {
            return {
                value: t,
                text: t.type
            };
        });
    }

    get resultFields() {
        return [{
            key: 'name',
            label: 'Name',
            sortable: true
        }, {
            key: 'type',
            label: 'Type',
            sortable: true
        }, {
            key: 'city',
            label: 'City',
            sortable: true
        }, {
            key: 'state',
            label: 'State/Province',
            sortable: true

        }, {
            key: 'status',
            label: 'Status',
            sortable: true
        }, {
            key: 'buttons',
            label: '',
            sortable: false
        }];
    }

    get results() {
        const result = _.chain(this.$store.getters['organizations/getOrganizations'])
            .filter((org) => !!org.type) // Organizations are considered contractors
                                         // when they have a Contractor Type
            .filter((org) => {
                const filter = trimToNull(this.contractorNameFilter);
                return null === filter || new RegExp(filter, 'i').test(org.name);
            })
            .filter((org) => {
                const filter = trimToNull(this.contactNameFilter);
                if (null === filter) {
                    return true;
                }
                const filterNames = filter.split(/\s+/);
                const filterFirstName = filterNames[0];
                const filterLastName = 1 < filterNames.length ? trimToNull(filterNames[1]) : null;
                return org.hasContactWithName(filterFirstName, filterLastName);
            })
            .filter((org) => {
                const filter = this.typeFilter;
                return null === filter || filter.id === (org.type || {}).id;
            })
            .filter((org) => {
                const filter = trimToNull(this.cityFilter);
                return null === filter || new RegExp(this.cityFilter, 'i').test(org.city);
            })
            .filter((org) => {
                const filter = this.stateFilter;
                if (null === filter) {
                    return true;
                }
                if (!org.state) {
                    return false;
                }
                return _.isObject(org.state) ? org.state.id === filter.id :
                    (filter.stateAbbreviation.toLowerCase() === (org.state || '').toLowerCase());
            })
            .filter((org) => {
                const filter = this.statusFilter;
                return null === filter || org.participating === filter;
            })
            .sortBy((org) => org.name)
            .value();
        // console.log(result);
        return result;
    }

    clearFilters() {
        this.contractorNameFilter = null;
        this.contactNameFilter = null;
        this.typeFilter = null;
        this.cityFilter = null;
        this.stateFilter = null;
        this.statusFilter = null;
    }

    async exportContractors() {
        const criteria = NULL_ORGANIZATION.clone();
        criteria.name = this.contractorNameFilter;
        const contactName = trimToNull(this.contactNameFilter);
        if (null !== contactName) {
            const criteriaContact = new OrganizationContact({});
            const names = contactName.split(/\s+/);
            const fname = names[0];
            const lname = 1 < names.length ? trimToNull(names[1]) : null;
            criteriaContact.firstName = fname;
            if (!_.isNull(lname)) {
                criteriaContact.lastName = lname;
            }
            criteria.contacts.push(criteriaContact);
        }

        criteria.type = this.typeFilter;
        criteria.city = this.cityFilter;
        criteria.state = this.stateFilter;
        criteria.participating = this.statusFilter;

        try {
            const criteriaJson = JSON.stringify(criteria);
            download('POST', '/api/v1/export/contractors', criteriaJson);
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    rowClick(item, idx, ev) {
        item._showDetails = !item._showDetails;
    }

    validateSave(organization) {
        const valid = organization.workspace.validate();

        if (!valid) {
            this.$bvModal.msgBoxOk('Please correct the highlighted errors before saving.', {
                size: 'sm',
                buttonSize: 'sm'
            });
        }

        return valid;
    }

    async saveOrganization(organization) {
        if (this.validateSave(organization)) {
            this.doSave(organization);
        }
    }

    async saveNewContractor(okEvent) {
        // Stop the modal from closing until the contractor has been fully saved
        okEvent.preventDefault();
        if (!this.validateSave(this.newContractor)) {
            return;
        }
        const saved = await this.doSave(this.newContractor);
        if (saved) {
            // Now it's okay to close the dialog
            this.newContractor = NULL_ORGANIZATION.clone();
            this.newContractor.workspace.contacts = [];
            // console.log(this.newContractor);
            this.$refs['add-contractor-modal'].hide();
        }
    }

    async doSave(organization) {
        try {
            await this.$store.dispatch('organizations/saveOrganization', organization, {root: true});
            return true;
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            return false;
        }
    }

    async beforeRouteEnter(to, from, next) {
        store.commit('setBreadcrumbs', [
            Breadcrumb.create('Administration', {name: 'adminMain'}, false),
            Breadcrumb.create('Contractors', {name: 'contractors'}, true)
        ]);
        try {
            const deps = [
                'organizations/loadOrganizations',
                'organizations/loadContractorTypes',
                'common/loadStates'
            ];
            _.each(deps, action => {
                store.dispatch(action)
                    .catch(error => {
                        const vm = new Vue();
                        vm.$bvToast.toast(error.message, errorToastOptions);
                    });
            });
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
        next();
    }

    mounted() {
        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
            if ('add-contractor-modal' === modalId) {
                this.newContractor = NULL_ORGANIZATION.clone();
                this.newContractor.workspace.participating = true;
                this.newContractor.workspace.validate();
            }
        });
    }
}
</script>
<style scoped>

</style>